import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'antd';

import Header from './header';

import './index.css';

const BellaSiena = (props) => {
  return (
    <div>
      <Header />
      <Row>
        <Col xl={8} xs={0}></Col>
        <Col xl={8} xs={24}>
          <Row>
            <Col xl={12} xs={12}>
              <div className="botao">
                <Link to="/ficha-interesse">
                  <Button type="primary" shape="round" block>FICHA CLIENTE</Button>
                </Link>
              </div>
            </Col>
            <Col xl={12} xs={12}>
              <div className="botao">
                <Link to="/">
                  <Button type="primary" shape="round" block>INÍCIO</Button>
                </Link>
              </div>
            </Col>
          </Row>          
        </Col>
        <Col xl={8} xs={0}></Col>
      </Row>
      <Row justify='center'>
        <Col xl={8} xs={0}></Col>
        <Col xl={8} xs={24}>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/logo.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Logo</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/implantacao.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Implantação</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/convencao_condominio.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Convenção Condominial</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/tabela.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Tabela Bella Siena</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/regimento_interno.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Regimento Interno</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/book.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Book</Button>
              </Link>            
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/bellasiena/ficha_cadastral.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Ficha Cadastral</Button>
              </Link>            
            </Col>
          </Row>
        </Col>
        <Col xl={8} xs={0}></Col>
      </Row>
    </div>
  )}

  export default BellaSiena;
