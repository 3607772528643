import React from 'react';
import {Row, Col} from 'antd';

const Header = (props) => {
  return (
    <Row>
      <Col span={24}>
        <img id="header" alt="" src="/images/empreendimentos/header_bellasiena.jpeg" width="100%" />
      </Col>
    </Row>
  )
}

export default Header;
